'use client';
import { IconFileSearch } from '@tabler/icons-react';
import React from 'react';
import { ScrollArea } from 'ui';

export const ClaimTabContent = ({
  children,
  withContentPadding = true,
  viewportClassName,
}: {
  children?: React.ReactNode;
  withContentPadding?: boolean;
  viewportClassName?: string;
}) => {
  return (
    <ScrollArea
      withContentPadding={withContentPadding}
      type='always'
      orientation='vertical'
      className='flex flex-col h-[600px] w-full'
      viewportClassName={viewportClassName}
    >
      {children}
    </ScrollArea>
  );
};

export const EmptyClaimTabContent = ({ label }: { label: string }) => {
  return (
    <div className='flex items-center justify-center h-96 text-center'>
      <div className='flex flex-col items-center justify-center space-y-4 text-gray-500'>
        <IconFileSearch className='w-16 h-16 ' />
        <p className='italic'>{label}</p>
      </div>
    </div>
  );
};
