'use client';
import { cn, Spinner } from 'ui';
import { PiramidLogo } from 'shared-components';
import { createPortal } from 'react-dom';
import { useDebounce } from '@uidotdev/usehooks';

export const PORTAL_ID = 'portal-root';

export const PiramidLoading = ({ isLoading }: { isLoading?: boolean }) => {
  const isLoadingDebounced = useDebounce(isLoading, 500);

  if (typeof window === 'undefined') return null;

  return createPortal(
    <div
      className={cn(
        'piramid-loading z-50 w-screen h-screen absolute inset-0 overflow-y-hidden flex flex-col items-center justify-center bg-black',
        {
          'visible opacity-0 transition-all': !isLoading,
          hidden: !isLoadingDebounced,
        },
      )}
    >
      <div className='flex flex-col items-center'>
        <PiramidLogo
          className='items-center justify-center flex'
          variant='white'
          size={'lg'}
        />
        <div className='flex flex-row items-center text-white'>
          <Spinner size='xs' />
          <p>Piramid</p>
        </div>
      </div>
    </div>,
    window.document.body,
  );
};
