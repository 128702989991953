'use client';

import { IconPlus } from '@tabler/icons-react';
import { useState } from 'react';
import {
  Badge,
  Button,
  ComboboxCheckbox,
  ComboboxOption,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
} from 'ui';

export type SearchTagsFn = (params: { search: string }) => Promise<{
  tags: {
    name: string;
    id: string;
  }[];
}>;

export const DocumentTagsFilter = ({
  selectedValues,
  setSelectedValues,
  searchTags,
}: {
  setSelectedValues: (selected: ComboboxOption[]) => void;
  selectedValues: ComboboxOption[];
  searchTags: SearchTagsFn;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover onOpenChange={setOpen} open={open}>
      <PopoverTrigger asChild>
        <Button variant='outline' size='sm' className='h-10 border-dashed'>
          <IconPlus className='mr-2 h-4 w-4' />
          Etiquetas
          {selectedValues.length > 0 && (
            <>
              <Separator orientation='vertical' className='mx-2 h-4' />
              <Badge
                variant='secondary'
                className='rounded-sm px-1 font-normal lg:hidden'
              >
                {selectedValues.length}
              </Badge>
              <div className='hidden space-x-1 lg:flex'>
                {selectedValues.length > 2 ? (
                  <Badge
                    variant='secondary'
                    className='rounded-sm px-1 font-normal'
                  >
                    {selectedValues.length} seleccionados
                  </Badge>
                ) : (
                  selectedValues
                    .filter((option) =>
                      selectedValues.some((ele) => ele.value === option.value),
                    )
                    .map((option, index) => (
                      <Badge
                        variant='secondary'
                        key={index}
                        className='rounded-sm px-1 font-normal'
                      >
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[200px] p-0' align='start'>
        <ComboboxCheckbox
          value={selectedValues}
          onClose={() => setOpen(false)}
          render={({ label }) => <>{label}</>}
          placeholder='Buscar etiquetas'
          onSelect={({ value, label }) => {
            if (selectedValues.some((ele) => ele.value === value)) {
              setSelectedValues(
                selectedValues.filter((ele) => ele.value !== value),
              );
            } else {
              setSelectedValues([...selectedValues, { label, value }]);
            }
          }}
          loadOptions={async (search) =>
            searchTags({ search }).then((res) =>
              res.tags.map((t) => {
                return {
                  label: t.name,
                  value: t.id.toString(),
                };
              }),
            )
          }
          defaultOptions={selectedValues}
          emptyStateMessage=''
        />
      </PopoverContent>
    </Popover>
  );
};
