import { ClaimOfferMinimalDetail } from 'bff';
import { useTranslations } from 'i11n';
import { Button } from 'ui';
import { FileListItem, FileListLabel } from './File';
import {
  OfferAgreementCard,
  OfferAgreementCardActionActor,
  OfferAgreementCardAttribute,
  OfferAgreementCardAttributeLabel,
  OfferAgreementCardAttributeValue,
  OfferAgreementCardContent,
  OfferAgreementCardFooter,
  OfferAgreementCardHeader,
  OfferAgreementCardStatus,
  OfferAgreementCardTitle,
  OfferAgreementDetail,
} from './OfferAgreementCard';
import { UserIdentity } from './UserDisplay';
import { currencyFormat } from 'utils';

export const ClaimOfferList = ({ children }: { children: React.ReactNode }) => {
  return <div className='grid grid-cols-1 gap-8'>{children}</div>;
};

export const ClaimOffer = ({
  offer,
  claim,
  index,
  onOpenSigningURL,
  withCallToAction = true,
  children,
  classNames,
}: {
  offer: ClaimOfferMinimalDetail;
  claim: {
    created_by: Omit<UserIdentity, 'id'>;
  };
  index: number;
  onOpenSigningURL?: () => void;
  withCallToAction?: boolean;
  children?: React.ReactNode;
  classNames?: {
    footer?: string;
  };
}) => {
  const t = useTranslations();

  return (
    <OfferAgreementCard>
      <OfferAgreementCardStatus status={offer.status} />
      <OfferAgreementCardHeader>
        <OfferAgreementCardTitle>Ofrecimiento</OfferAgreementCardTitle>
      </OfferAgreementCardHeader>
      <OfferAgreementCardContent>
        <OfferAgreementCardAttribute>
          <OfferAgreementCardAttributeLabel>
            Nombre
          </OfferAgreementCardAttributeLabel>
          <OfferAgreementCardAttributeValue>
            {offer.name}
          </OfferAgreementCardAttributeValue>
        </OfferAgreementCardAttribute>
        {offer.variable_values.map((variable) => {
          const name = variable.value.split('/').pop();

          return (
            <OfferAgreementCardAttribute key={variable.id}>
              <OfferAgreementCardAttributeLabel>
                {variable.name}
              </OfferAgreementCardAttributeLabel>
              {variable.type === 'file' ? (
                <a href={variable.value} className='group' target='_blank'>
                  <FileListItem className='group-hover:underline' key={index}>
                    <FileListLabel withDefaultIcon>{name}</FileListLabel>
                  </FileListItem>
                </a>
              ) : (
                <OfferAgreementCardAttributeValue>
                  {variable.value}
                </OfferAgreementCardAttributeValue>
              )}
            </OfferAgreementCardAttribute>
          );
        })}
      </OfferAgreementCardContent>
      <OfferAgreementCardFooter
        className={classNames?.footer}
        status={offer.status}
      >
        {!!offer.rejected_reason && (
          <div className='w-full space-y-2'>
            <p className='text-xs'>
              Motivo: "<span className='italic'>{offer.rejected_reason}"</span>
            </p>
            {offer.third_party_user_requested_amount && (
              <p className='text-xs'>
                Pretensión:{' '}
                {currencyFormat(offer.third_party_user_requested_amount)}
              </p>
            )}
            <OfferAgreementCardActionActor
              date={offer.rejected_at!}
              first_name={claim.created_by.first_name}
              last_name={claim.created_by.last_name}
            />
          </div>
        )}

        {offer.status === 'pending' && withCallToAction && (
          <a
            href={`/claim/offer/agreement?token=${offer.token}`}
            target='_blank'
          >
            <Button size='sm'>Responder propuesta</Button>
          </a>
        )}

        {children}
      </OfferAgreementCardFooter>
      {offer.agreement && <OfferAgreementDetail agreement={offer.agreement} />}
    </OfferAgreementCard>
  );
};
