'use client';

import { useRouter } from 'next/navigation';
import { Button, cn } from 'ui';
import { MicrosoftIcon } from './MicrosoftIcon';
import { useApiError } from '../hooks/useApiError';
import { useChildrenWindow } from '../hooks/useChildrenWindow';
import { useCallback, useMemo } from 'react';
import { oauthExchangedCodeMessage } from 'bff';

export const MicrosoftBtn = ({
  variant = 'color',
  ...props
}: {
  isLoading?: boolean;
  onAccept: (access_token: string) => void;
  small?: boolean;
  variant?: 'color' | 'dark';
}) => {
  const url = useMemo(() => {
    const basePath = 'https://login.microsoftonline.com';
    const url = new URL(`${basePath}/common/oauth2/v2.0/authorize`);
    url.searchParams.append(
      'client_id',
      process.env.NEXT_PUBLIC_AZURE_CLIENT_ID!,
    );
    url.searchParams.append('response_type', 'code');
    url.searchParams.append(
      'redirect_uri',
      process.env.NEXT_PUBLIC_AZURE_REDIRECT_URI!,
    );
    url.searchParams.append('response_mode', 'query');
    url.searchParams.append(
      'scope',
      'https://graph.microsoft.com/User.Read offline_access https://graph.microsoft.com/mail.read',
    );
    return url.toString();
  }, []);

  const onMessage = useCallback((event: MessageEvent<any>) => {
    try {
      const message = oauthExchangedCodeMessage.safeParse(
        JSON.parse(event.data),
      );

      console.log('received message', message);

      if (message.success) {
        props.onAccept(message.data.access_token);
      }
    } catch (err) {
      console.error('error when read message', err);
    }
  }, []);

  const { openChildrenWindow } = useChildrenWindow({
    onMessage,
    url,
  });

  return (
    <div className='py-2'>
      <Button
        loading={props.isLoading}
        variant={'outline'}
        className={cn(
          variant === 'dark'
            ? 'bg-beige text-piramid-black hover:bg-beige border-0 rounded-[.625rem]'
            : 'bg-gray-100 hover:bg-gray-200',
          'w-full h-11.1',
        )}
        onClick={() => openChildrenWindow()}
      >
        <MicrosoftIcon
          style={{
            width: !props.small ? '1.6rem' : '1.3rem',
          }}
          variant={variant}
        />
        <span className='ml-2 font-semibold text-sm'>
          Continuar con Microsoft
        </span>
      </Button>
    </div>
  );
};
