import * as React from 'react';
import { cn } from '../lib/utils';

const CardGeneric = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      'bg-white flex flex-row items-center justify-between rounded-[0.5rem] border border-stone-300 py-[0.875rem] px-[1.25rem]',
      className,
    )}
    {...props}
  />
));

export { CardGeneric };
