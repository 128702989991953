'use client';

import * as React from 'react';
import { X } from 'lucide-react';

import { Badge, cn, inputVariants } from 'ui';
import { Command, CommandGroup, CommandItem, CommandList } from 'ui';
import { Command as CommandPrimitive } from 'cmdk';
import { IconSearch } from '@tabler/icons-react';

interface Option {
  label: string;
  value: string;
}

type ClassNameBuilder = (states: { open: boolean }) => string;

export const FancyMultiSelect = ({
  options,
  onChange,
  value,
  classNames,
  placeholder,
  list,
  styles,
}: {
  options: Option[];
  onChange: (options: Option[]) => void;
  value: Option[];
  styles?: {
    selected?: {
      item?: React.CSSProperties;
      innerContainer?: React.CSSProperties;
    };
  };
  classNames?: {
    inputContainer?: ClassNameBuilder;
    input?: ClassNameBuilder;
    icon?: ClassNameBuilder;
    innerContainer?: ClassNameBuilder;
    list?: {
      title?: string;
      container?: ClassNameBuilder;
      group?: string;
      item?: string;
    };
    selected?: {
      item?: string;
      button?: string;
    };
  };
  placeholder?: string;
  list?: {
    title?: string;
  };
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  const handleUnselect = React.useCallback(
    (option: Option) => {
      const nextSelected = value.filter((s) => s.value !== option.value);
      onChange(nextSelected);
    },
    [value],
  );

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current;
      if (input) {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          if (input.value === '') {
            const indexToRemove = value.length - 1;

            const nextSelected = [
              ...value.slice(0, indexToRemove),
              ...value.slice(indexToRemove + 1),
            ];

            onChange(nextSelected);
          }
        }
        if (e.key === 'Escape') {
          input.blur();
        }
      }
    },
    [value],
  );

  const selectables = options.filter(
    (option) => !value.some((selected) => selected.value === option.value),
  );

  return (
    <Command
      onKeyDown={handleKeyDown}
      className='overflow-visible bg-transparent'
    >
      <div
        className={cn(
          'group rounded-md border border-input px-3 py-2 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2',
          classNames?.inputContainer
            ? classNames?.inputContainer({ open })
            : '',
        )}
      >
        <div
          style={
            styles?.selected?.innerContainer
              ? styles?.selected.innerContainer
              : {}
          }
          className={cn(
            'flex flex-wrap gap-1',
            classNames?.innerContainer
              ? classNames?.innerContainer({ open })
              : '',
          )}
        >
          {!value.length && (
            <IconSearch
              className={cn(
                'w-4 h-4',
                classNames?.icon ? classNames?.icon({ open }) : '',
              )}
            />
          )}
          {value.map((option) => {
            return (
              <Badge
                style={styles?.selected?.item ? styles?.selected.item : {}}
                className={cn(
                  'text-stone-700 bg-stone-100 font-medium',
                  classNames?.selected?.item,
                )}
                key={option.value}
                variant='secondary'
              >
                {option.label}
                <button
                  type='button'
                  className={cn(
                    'ml-1 rounded-sm outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2',
                    classNames?.selected?.button,
                  )}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleUnselect(option);
                    }
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onClick={() => handleUnselect(option)}
                >
                  <X className='h-3 w-3 text-stone-300 hover:text-foreground' />
                </button>
              </Badge>
            );
          })}
          <CommandPrimitive.Input
            ref={inputRef}
            value={inputValue}
            onValueChange={setInputValue}
            onBlur={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            placeholder={placeholder}
            className={cn(
              'ml-2 flex-1 bg-transparent outline-none placeholder:text-muted-foreground',
              classNames?.input ? classNames?.input({ open }) : '',
            )}
          />
        </div>
      </div>
      <div className='relative mt-2'>
        <CommandList withScroll={false}>
          {open && selectables.length > 0 ? (
            <div
              className={cn(
                'fancy-multiselect-list-container absolute top-0 z-10 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in',
                classNames?.list?.container
                  ? classNames?.list?.container({ open })
                  : '',
              )}
            >
              {list?.title && (
                <p className={classNames?.list?.title}>{list?.title}</p>
              )}

              <CommandGroup
                className={cn('h-full overflow-auto', classNames?.list?.group)}
              >
                {selectables.map((option) => {
                  return (
                    <CommandItem
                      key={option.value}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onSelect={() => {
                        setInputValue('');

                        const nextSelected = [...value, option];

                        onChange(nextSelected);
                      }}
                      className={cn('cursor-pointer', classNames?.list?.item)}
                    >
                      {option.label}
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </div>
          ) : null}
        </CommandList>
      </div>
    </Command>
  );
};
