'use client';

import { Icon, IconCheck } from '@tabler/icons-react';
import * as React from 'react';
import { cn } from '../lib/utils';
import { Button, ButtonProps } from './Button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './Command';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';
import { ScrollArea } from './ScrollArea';
import { ComboboxOption } from './Combobox';
import debounce from 'lodash.debounce';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './Tooltip';

export function AsyncFancyBox<T extends ComboboxOption>({
  selectedValues = [],
  emptyStateMessage = 'Ninguna opción',
  pluralLabel = 'opciones seleccionadas',
  inputPlaceholder = 'Buscar opciones',
  btnSize = 'input-sm',
  btnClassName,
  LeftIcon,
  RightIcon,
  containerClassName,
  loadOptions,
  defaultOptions = [],
  onAppend,
  onRemove,
  components,
  classNames,
}: {
  pluralLabel?: string;
  emptyStateMessage?: string;
  inputPlaceholder?: string;
  btnSize?: ButtonProps['size'];
  LeftIcon?: Icon;
  RightIcon?: Icon;
  containerClassName?: string;
  selectedValues: ComboboxOption[];
  label?: string;
  loadOptions: (search: string) => Promise<T[]>;
  defaultOptions?: ComboboxOption[];
  btnClassName?: string;
  onAppend: (selected: ComboboxOption) => void;
  onRemove: (selected: ComboboxOption) => void;
  components?: {
    option?: React.FC<ComboboxOption>;
  };
  classNames?: {
    item?: string;
  };
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [openCombobox, setOpenCombobox] = React.useState(false);

  const onComboboxOpenChange = (value: boolean) => {
    inputRef.current?.blur(); // HACK: otherwise, would scroll automatically to the bottom of page
    setOpenCombobox(value);
  };

  const [isLoading, setIsLoading] = React.useState(false);

  const [buttonWidth, setButtonWidth] = React.useState(0);

  const [opts, setOpts] = React.useState<T[]>(
    !!defaultOptions && Array.isArray(defaultOptions) ? defaultOptions : [],
  );

  const wrappedFunc = React.useCallback((search: string = '') => {
    if (!search) {
      setOpts([]);
      setIsLoading(false);
      return;
    }

    loadOptions(search)
      .then(setOpts)
      .finally(() => setIsLoading(false));
  }, []);

  const debounced = React.useCallback(debounce(wrappedFunc, 500), []);

  React.useEffect(() => {
    loadOptions('').then(setOpts);
  }, []);

  const previewLabel = React.useMemo(() => {
    if (selectedValues.length === 0) return inputPlaceholder;

    if (selectedValues.length === 1) return selectedValues[0].label;

    if (selectedValues.length === 2)
      return selectedValues.map(({ label }) => label).join(', ');

    if (selectedValues.length > 2)
      return `${selectedValues.length} ${pluralLabel}`;
  }, [selectedValues.length]);

  return (
    <div className={cn('max-w-full', containerClassName)}>
      <Popover open={openCombobox} onOpenChange={onComboboxOpenChange}>
        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>
              <PopoverTrigger asChild>
                <Button
                  ref={(ele) => {
                    if (ele) {
                      setButtonWidth(ele.offsetWidth);
                    }
                  }}
                  size={btnSize}
                  variant='outline'
                  role='combobox'
                  aria-expanded={openCombobox}
                  className={cn(
                    'w-full justify-start text-foreground relative',
                    btnClassName,
                  )}
                >
                  {LeftIcon && (
                    <LeftIcon className='mr-2 h-4 w-4 shrink-0 opacity-50' />
                  )}

                  <div className='flex flex-row justify-between w-full items-center'>
                    <span className='truncate'>{previewLabel}</span>
                  </div>
                  {RightIcon && (
                    <div className='absolute right-2'>
                      <RightIcon className='h-4 w-4 shrink-0 opacity-50' />
                    </div>
                  )}
                </Button>
              </PopoverTrigger>
            </TooltipTrigger>
            <TooltipContent
              className={cn({
                'opacity-0': !selectedValues.length,
                'opacity-100': !!selectedValues.length,
              })}
            >
              <p>{previewLabel}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <PopoverContent
          align='center'
          className='p-0'
          style={{
            width: buttonWidth,
          }}
        >
          <Command shouldFilter={false}>
            <CommandInput
              loading={isLoading}
              placeholder={inputPlaceholder}
              onValueChange={(search) => {
                setIsLoading(true);
                debounced(search);
              }}
            />
            <CommandList>
              <CommandEmpty>
                {isLoading ? 'Buscando...' : emptyStateMessage}
              </CommandEmpty>
              <CommandGroup>
                <ScrollArea
                  orientation='vertical'
                  className='flex flex-col max-h-[145px]'
                >
                  {opts.map((option) => {
                    const isActive = selectedValues.some(
                      (selected) => selected.value === option.value,
                    );

                    return (
                      <CommandItem
                        key={option.value}
                        value={option.value?.toString()}
                        onSelect={() => {
                          if (isActive) {
                            onRemove(option);
                          }

                          if (!isActive) {
                            onAppend(option);
                          }
                        }}
                        className={classNames?.item}
                      >
                        {components?.option ? (
                          components.option(option)
                        ) : (
                          <div className='flex-1'>{option.label}</div>
                        )}
                        <IconCheck
                          className={cn(
                            'mr-2 h-4 w-4',
                            isActive ? 'opacity-100' : 'opacity-0',
                          )}
                        />
                      </CommandItem>
                    );
                  })}
                </ScrollArea>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
