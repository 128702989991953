'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';
import { cn } from 'ui';

export type Tab = {
  path: string;
  label?: string;
  builder?: (path: string) => string;
  strategy?: 'strict' | 'includes';
  parentPath?: string;
};

export const BaseTabs = ({
  prefix,
  tabsConfig,
  identifiers,
}: {
  prefix: string;
  tabsConfig: Array<Tab | null>;
  identifiers?: Record<string, string | number>;
}) => {
  const path = usePathname();

  const tabs = useMemo(() => {
    const list = tabsConfig
      .map((tab) => {
        if (tab?.builder && identifiers) {
          tab.path = tab.builder(tab.path);
        }
        return tab;
      })
      .filter((tab): tab is NonNullable<typeof tab> => tab !== null);

    return list;
  }, [tabsConfig, identifiers]);

  const currentTab = useMemo(() => {
    return tabs.find((tab) => {
      switch (tab.strategy) {
        case 'strict':
          return path === tab.path;
        case 'includes':
          return path.includes(tab.parentPath!);
        default:
          return false;
      }
    })?.path;
  }, [path, tabs]);

  return (
    <div className='flex flex-col space-y-4 mt-2'>
      {tabs.map((tab) => (
        <Link
          className={cn({
            'font-semibold': tab.path === currentTab,
          })}
          href={tab.path}
          key={tab.path}
        >
          {tab.label}
        </Link>
      ))}
    </div>
  );
};
