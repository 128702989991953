import { usePathname } from 'next/navigation';

export type PathSlugs = Array<string[]>;

export const useEvaluatePathSlug = (pathSlugs: PathSlugs) => {
  const pathname = usePathname();

  return pathSlugs.some((segments) =>
    segments.every((segment) => {
      if (segments.length === 1) return pathname.startsWith(segment);

      return pathname.includes(segment);
    }),
  );
};
