'use client';

import { useMemo } from 'react';
import { Permission } from 'database';
import {
  MenuItem,
  SidebarDashboardLayout,
  useSharedAuth,
} from 'shared-components';
import { IconFocusCentered } from '@tabler/icons-react';

export const CustomerDashboardLayout = ({
  logout,
  children,
}: {
  logout: () => Promise<any>;
  children: React.ReactNode;
}) => {
  const { user } = useSharedAuth();

  const tabs = useMemo(() => {
    const intermediateTabs: Array<
      MenuItem & {
        show: (permissions: Permission[]) => boolean;
      }
    > = [
      {
        icon: IconFocusCentered,
        // withoutClientSideRouting: true,
        title: 'Reclamos',
        href: '/claims',
        show: (permissions: Permission[]) =>
          (permissions.some((p) => p.type === 'can_read_claim') &&
            user.companyEntrypoints!.some(
              (entrypoint) => entrypoint === 'claim',
            )) ||
          user.type === 'third',
      },
      {
        icon: IconFocusCentered,
        // withoutClientSideRouting: true,
        title: 'Denuncias',
        href: '/complaints',
        show: (permissions: Permission[]) =>
          permissions.some((p) => p.type === 'can_read_complaint') &&
          user.companyEntrypoints!.some(
            (entrypoint) => entrypoint === 'complaint',
          ),
      },
    ];

    return intermediateTabs.filter((tab) => tab.show(user.role.permissions));
  }, [user]);

  return (
    <SidebarDashboardLayout
      nextSlugs={[['/settings'], ['/sinister/', '/claim/'], ['/claims/']]}
      items={tabs}
      logoutUser={logout}
    >
      {children}
    </SidebarDashboardLayout>
  );
};
