import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { CalendarIcon } from 'lucide-react';
import { useState } from 'react';
import { Control, useFormContext } from 'react-hook-form';
import {
  Button,
  Calendar,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Popover,
  PopoverContent,
  PopoverTrigger,
  cn,
} from 'ui';

export const FormFieldCalendar = ({
  key,
  path,
  defaultValue,
  label,
  value,
  field,
  itemClassName,
}: {
  key?: string;
  path: string;
  defaultValue: Date | null;
  label: string;
  value: any;
  field?: {
    fromDate?: Date;
    setter?: (value: Date) => void;
  };
  itemClassName?: string;
}) => {
  const form = useFormContext();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <FormField
      key={key}
      name={path}
      control={form.control}
      defaultValue={defaultValue}
      render={() => {
        return (
          <FormItem className={cn(itemClassName)}>
            <Popover open={isOpen}>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    data-test-id-label={`date-picker-${label}`}
                    data-test-id='date-picker'
                    variant={'outline'}
                    className={cn(
                      'w-full pl-3 text-left font-normal border-piramid-black placeholder:text-[.875rem] placeholder:text-[#B7B7C0] placeholder:font-normal',
                      !value && 'text-muted-foreground',
                    )}
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {value ? (
                      format(new Date(value), 'PPP', {
                        locale: es,
                      })
                    ) : (
                      <span>{label}</span>
                    )}
                    <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className='w-auto p-0' align='start'>
                <Calendar
                  fromDate={field?.fromDate}
                  mode='single'
                  selected={value}
                  onSelect={(day) => {
                    if (field?.setter) {
                      field.setter(day!);
                    } else if (path) {
                      form.setValue(path, day);
                    }
                    setIsOpen(!isOpen);
                  }}
                  disabled={(date) =>
                    date > new Date() || date < new Date('1900-01-01')
                  }
                  initialFocus
                  className='placeholder:text-[.875rem] placeholder:text-[#B7B7C0] placeholder:font-normal'
                />
              </PopoverContent>
            </Popover>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};
