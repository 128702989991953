'use client';
import { FetchInsurerMinimalClaimDetailResponseBody } from 'bff';
import React from 'react';
import { EnablementProvider } from '..';

export const ClaimEnablementProvider = ({
  claim,
  children,
}: {
  claim: FetchInsurerMinimalClaimDetailResponseBody;
  children: React.ReactNode;
}) => {
  return (
    <EnablementProvider
      reason='El reclamo se encuentra cerrado'
      disabled={claim.system_status === 'closed'}
    >
      {children}
    </EnablementProvider>
  );
};
