import React, { FC, useMemo, MouseEvent } from 'react';
import { ButtonProps } from '..';
import { Icon } from 'lucide-react';
import { Button, PaginationButton, PaginationItem, cn } from 'ui';
import { usePaginationContext } from '../hooks/usePaginationContext';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { INITIAL_VALUES } from '../constants/pagination';

interface IconBaseProps extends React.SVGAttributes<SVGElement> {
  children?: React.ReactNode;
  size?: string | number;
  color?: string;
  title?: string;
}

export type SeparatorPosition = 'left' | 'right';

export type IconType = (props: IconBaseProps) => JSX.Element;

type SeparatorProps = {
  hoverIcon?: IconType;
  jumpSize?: number;
  isDisabled?: boolean;
  separatorPosition?: SeparatorPosition;
};

const separatorStyles: ButtonProps = {
  cursor: 'pointer',
  minW: 'auto',
  justifyContent: 'center',
  pos: 'relative',
  alignItems: 'center',
  bg: 'transparent',
  px: 1,
  sx: {
    _hover: {
      '.call-to-action': {
        opacity: 1,
      },
    },
  },
};

const separatorIconStyles: IconProps = {
  h: 4,
  w: 4,
  bg: 'inherit',
  color: 'inherit',
};

const separatorTransitionStyles: IconProps = {
  m: 'auto',
  pos: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  opacity: 0,
  transition: 'all  cubic-bezier(0.4, 1, 0.9, 0.6) 0.3s',
};

export const PaginationWindowSeparator: FC<SeparatorProps & ButtonProps> = ({
  hoverIcon,
  separatorPosition,
  isDisabled: isDisabledProp,
  jumpSize = INITIAL_VALUES.jumpSize,
  ...buttonProps
}) => {
  // provider
  const { actions, state } = usePaginationContext();
  const { currentPage, pagesCount, isDisabled: isDisabledGlobal } = state;
  const { changePage } = actions;

  // methods
  const getPageToJump = (separatorPosition?: SeparatorPosition): number => {
    if (separatorPosition === 'left') return currentPage - jumpSize;
    if (separatorPosition === 'right') return currentPage + jumpSize;

    return 0;
  };

  const determineJumpAllowance = (
    separatorPosition?: SeparatorPosition,
  ): boolean => {
    if (separatorPosition === 'left') {
      return currentPage - jumpSize > 0;
    }

    if (separatorPosition === 'right') {
      return currentPage + jumpSize < pagesCount + 1;
    }

    return false;
  };

  const getSeparatorProps = ({
    onClick,
    ...props
  }: ButtonProps): ButtonProps => ({
    ...props,
    'aria-label': `Jump pages ${jumpingDirectionLabel}`,
    'aria-disabled': isDisabled,
    onClick: (event: MouseEvent<HTMLButtonElement>) => {
      if (!isDisabled) {
        onClick?.(event);
      }

      handleJumpClick();
    },
  });

  const canJump = determineJumpAllowance(separatorPosition);

  const isDisabled = useMemo(
    () => !canJump || (isDisabledProp ?? isDisabledGlobal),
    [canJump, isDisabledProp, isDisabledGlobal],
  );

  const jumpingDirectionLabel = useMemo(
    () => (separatorPosition === 'left' ? 'backwards' : 'forward'),
    [separatorPosition],
  );

  const allProps = useMemo(
    () => ({
      ...separatorStyles,
      ...buttonProps,
    }),
    [buttonProps],
  );

  // handlers
  const handleJumpClick = (): void => {
    if (isDisabled) return;

    const pageToJump = getPageToJump(separatorPosition);

    changePage(pageToJump);
  };

  const Icon =
    separatorPosition === 'left' ? IconChevronLeft : IconChevronRight;

  return (
    <PaginationButton
      onClick={() => {
        handleJumpClick();
      }}
      className={cn(
        'w-[1.6875rem] h-[1.25rem] text-[0.625rem] rounded-[0.375rem] border-[0.0625rem] group relative',
      )}
      href='#'
    >
      <span className='opacity-0'>-1</span>
      <div className='absolute'>
        <Icon className='h-3 w-3 text-zinc-200 group-hover:text-black' />
      </div>
    </PaginationButton>
  );
};
