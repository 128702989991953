import {
  Icon,
  IconFile,
  IconFileMusic,
  IconFileTypePdf,
  IconHeadphones,
  IconPhoto,
  IconPlayerPlay,
  IconQuestionMark,
  IconVideo,
} from '@tabler/icons-react';
import { FileTypes } from 'database';
import { format } from 'date-fns';
import {
  Badge,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  cn,
} from 'ui';
import { mimetypes } from './FileInput';
import React from 'react';

export const iconsByFileType: {
  [K in FileTypes]: Icon;
} = {
  pdf: IconFileTypePdf,
  other: IconQuestionMark,
  audio: IconFileMusic,
  document: IconFile,
  image: IconPhoto,
  video: IconVideo,
};

export const getFileType = (fileMimetype: string | null): FileTypes => {
  if (!fileMimetype) return 'other';

  for (const type in mimetypes) {
    const list = mimetypes[type as keyof typeof mimetypes];

    if (list.includes(fileMimetype)) {
      return type as FileTypes;
    }
  }

  return 'other';
};

export const FileCardList = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn('grid grid-cols-3 lg:grid-cols-4 gap-8', className)}>
      {children}
    </div>
  );
};

export const FileCard = React.forwardRef<
  React.ElementRef<typeof Card>,
  React.ComponentPropsWithoutRef<typeof Card>
>(({ className, ...props }, ref) => {
  return (
    <Card
      {...props}
      className={cn(
        'relative group h-full cursor-pointer hover:border-primary transition-all',
        className,
      )}
      ref={ref}
    />
  );
});

const FilePreview = ({ children }: { children: React.ReactNode }) => {
  return <div className='h-40 w-full relative'>{children}</div>;
};

const FilePreviewThumbnail = ({
  previewURL,
  type,
}: {
  previewURL: string;
  type: FileTypes;
}) => {
  return (
    <>
      <img
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = '/images/preview-placeholder.jpg';
        }}
        className={cn(
          'h-full w-full rounded-md border shadow object-top object-cover',
          {
            'object-center': type === 'image',
          },
        )}
        src={previewURL}
      />
      {type === 'video' && (
        <div className='absolute inset-0 flex w-full h-full items-center justify-center opacity-60'>
          <div className='rounded-full p-2 bg-primary/50'>
            <IconPlayerPlay className='text-white w-5 h-5' />
          </div>
        </div>
      )}
    </>
  );
};

export const FileCardPreview = ({
  type,
  previewURL,
}: {
  type: FileTypes;
  previewURL?: string;
}) => {
  return (
    <FilePreview>
      {type === 'audio' ? (
        <div className='h-full w-full bg-slate-50 flex items-center justify-center'>
          <IconHeadphones className='h-20 w-20 text-primary' />
        </div>
      ) : (
        <FilePreviewThumbnail previewURL={previewURL} type={type} />
      )}
    </FilePreview>
  );
};

export const FileCardContent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <CardContent className='flex items-center justify-center'>
      {children}
    </CardContent>
  );
};
export const FileCardTag = ({ children }: { children: React.ReactNode }) => {
  return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger>
          <Badge
            className='text-xs bg-card group-hover:text-secondary group-hover:bg-primary'
            variant={'outline'}
          >
            <span className='max-w-[6rem] w-full truncate'>{children}</span>
          </Badge>
        </TooltipTrigger>
        <TooltipContent>
          <p className='text-xs'>{children}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const FileCardTagList = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className='flex flex-row gap-3 flex-wrap'>
      <div className='absolute right-0 -top-3'>
        <div className='flex flex-row flex-wrap gap-2'>{children}</div>
      </div>
    </div>
  );
};

export const FileCardFilename = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <CardTitle className='flex-row space-x-4 items-start truncate block !my-0'>
      {children}
    </CardTitle>
  );
};

export const FileCardIcon = ({
  mimetype,
  lookupMode = 'calculate',
}: {
  mimetype: string;
  lookupMode?: 'calculate' | 'rely';
}) => {
  const Icon =
    iconsByFileType[
      lookupMode === 'calculate'
        ? getFileType(mimetype)
        : (mimetype as keyof typeof iconsByFileType)
    ];

  if (!Icon) return null;

  return <Icon className='w-2 h-2 mr-2 flex-shrink-0' />;
};

export const FileCardHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <CardHeader className='flex flex-row items-center'>{children}</CardHeader>
  );
};

export const FileCardAuthor = ({
  uploadedByEmail,
  createdAt,
}: {
  uploadedByEmail?: string;
  createdAt: string | Date;
}) => {
  return (
    <div>
      <p className='text-muted-foreground'>{uploadedByEmail} </p>
      <time className='text-muted-foreground'>
        {format(new Date(createdAt), 'dd/MM/yyyy HH:mm')}
      </time>
    </div>
  );
};

export const FileCardFooter = ({ children }: { children: React.ReactNode }) => {
  return (
    <CardFooter className='flex flex-col text-xs space-y-4 items-start'>
      {children}
    </CardFooter>
  );
};
