'use client';

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ControllerRenderProps, useFormContext } from 'react-hook-form';
import { sortListAlphabetically } from 'utils';
import { useApiError } from '..';
import {
  ComboboxOption,
  ScrollArea,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  cn,
} from 'ui';
import { Virtualizer, VirtualizerHandle } from 'virtua';

interface FieldBase {
  label: string;
  virtualized?: boolean;
  withOptionSorted?: boolean;
  setter?: (selected: ComboboxOption) => void;
  resetOnFieldChange?: () => string;
}

interface AsyncField extends FieldBase {
  isFuture: true;
  options: (ctx: any) => Promise<ComboboxOption[]>;
}

interface SyncField extends FieldBase {
  isFuture: false;
  options: (ctx: any) => ComboboxOption[];
}

export type SelectAwaitedField = AsyncField | SyncField;

export const SelectAwaited = ({
  field,
  ctrlField,
  value,
  path,
  classNames,
  variant,
}: {
  path: string;
  value?: string;
  ctrlField: ControllerRenderProps;
  field: SelectAwaitedField;
  classNames?: {
    button?: string;
  };
  variant?: string;
}) => {
  const withOptionSorted =
    'withOptionSorted' in field ? field.withOptionSorted : true;

  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(field.isFuture ? true : false);

  const form = useFormContext();

  const [options, setOptions] = useState<Array<ComboboxOption>>(() =>
    field.isFuture ? [] : field.options(form.getValues()),
  );

  const { handleError } = useApiError();

  const scrollRef = useRef<HTMLDivElement>(null);
  const virtualizerRef = useRef<VirtualizerHandle>(null);

  const sortedOptions = useMemo(
    () =>
      withOptionSorted ? sortListAlphabetically(options, 'label') : options,
    [options],
  );

  const index = useMemo(
    () => sortedOptions.findIndex((opt) => opt.value === value),
    [options, value],
  );

  const handleOptionChange = useCallback(
    (value: string) => {
      if (field.setter) {
        const selected = sortedOptions.find((opt) => opt.value === value);

        field.setter(selected!);
      } else if (path) {
        form.setValue(path, value);
      }
    },
    [sortedOptions],
  );

  useEffect(() => {
    if (field.isFuture === true && field.options) {
      field
        .options(form.getValues())
        .then(setOptions)
        .catch(handleError)
        .finally(() => setIsLoading(false));
    }
  }, []);

  useEffect(() => {
    if (open && index !== -1 && virtualizerRef.current) {
      virtualizerRef.current?.scrollToIndex(index);
    }
  }, [open]);

  const resetOnFieldChangeComputed = useMemo(
    () => (field.resetOnFieldChange ? field.resetOnFieldChange() : ''),
    [field.resetOnFieldChange],
  );

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current && resetOnFieldChangeComputed) {
      console.log(
        'reset select',
        field.label,
        'due to change on field',
        resetOnFieldChangeComputed,
        'isMounted',
        isMounted.current,
      );

      handleOptionChange('');

      if (field.options) {
        if (field.isFuture) {
          (field.options(form.getValues()) as Promise<ComboboxOption[]>)
            .then(setOptions)
            .catch(handleError)
            .finally(() => setIsLoading(false));
        }

        if (!field.isFuture) {
          setOptions(field.options(form.getValues()));
        }
      }
    }

    if (!isMounted.current) {
      isMounted.current = true;
    }
  }, [
    resetOnFieldChangeComputed ? form.watch(resetOnFieldChangeComputed) : '',
  ]);

  const [touchStartTime, setTouchStartTime] = useState(0);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleTouchStart = useCallback(
    (e: React.TouchEvent<HTMLButtonElement>) => {
      setTouchStartTime(Date.now());
    },
    [],
  );

  const handleTouchEnd = useCallback(
    (e: React.TouchEvent<HTMLButtonElement>) => {
      const touchDuration = Date.now() - touchStartTime;
      if (touchDuration > 200) {
        setIsSelectOpen(true);
      } else {
        e.preventDefault();
      }
    },
    [],
  );

  return (
    <Select
      {...ctrlField}
      open={open}
      onOpenChange={setOpen}
      defaultValue={value}
      disabled={isLoading}
      onValueChange={handleOptionChange}
    >
      <SelectTrigger
        data-test-id={`select-trigger-${path}`}
        className={cn('w-full', classNames?.button)}
        variant={variant}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <SelectValue
          placeholder={isLoading ? 'Cargando opciones...' : 'Elegí una opción'}
        >
          {sortedOptions.find((opt) => opt.value === value)?.label || (
            <span className='text-[.875rem] text-[#B7B7C0] font-normal'>
              Elegí una opción
            </span>
          )}
        </SelectValue>
      </SelectTrigger>
      <SelectContent
        className='md:h-full h-[10rem] overflow-y-auto'
        asChild
        side='bottom'
      >
        {!isLoading && (
          <div
            ref={scrollRef}
            className='h-full md:max-h-40 max-h-30 overflow-y-scroll flex flex-col'
          >
            {field.virtualized ? (
              <Virtualizer ref={virtualizerRef} scrollRef={scrollRef}>
                {sortedOptions.map((option, index) => {
                  return (
                    <SelectItem
                      data-select-option={option.value}
                      key={index}
                      value={option.value?.toString()}
                    >
                      {option.label}
                    </SelectItem>
                  );
                })}
              </Virtualizer>
            ) : (
              sortedOptions.map((option, index) => {
                return (
                  <SelectItem
                    data-select-option={option.value}
                    key={index}
                    value={option.value?.toString()}
                  >
                    {option.label}
                  </SelectItem>
                );
              })
            )}
          </div>
        )}
      </SelectContent>
    </Select>
  );
};
