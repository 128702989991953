'useclient';

import { useRef } from 'react';
import {
  AriaTimeFieldProps,
  TimeValue,
  useDateSegment,
  useLocale,
  useTimeField,
} from 'react-aria';
import {
  useTimeFieldState,
  DateSegment as IDateSegment,
  DateFieldState,
} from 'react-stately';
import { cn } from '../lib/utils';
import { buttonVariants } from './Button';

interface DateSegmentProps {
  segment: IDateSegment;
  state: DateFieldState;
  className?: string;
}

function DateSegment({ segment, state, className }: DateSegmentProps) {
  const ref = useRef(null);

  const {
    segmentProps: { ...segmentProps },
  } = useDateSegment(segment, state, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      className={cn(
        'focus:rounded-[2px] focus:bg-primary focus:text-white focus:outline-none',
        segment.type !== 'literal' ? 'px-[1px]' : '',
        segment.isPlaceholder ? 'text-[.875rem] text-[#B7B7C0]' : '',
        className,
      )}
    >
      {segment.text}
    </div>
  );
}

export { DateSegment };

function TimeField({
  withDefaultStyles = true,
  className,
  classNames,
  ...props
}: AriaTimeFieldProps<TimeValue> & {
  className?: string;
  withDefaultStyles?: boolean;
  classNames?: {
    dateSegment?: string;
  };
}) {
  const ref = useRef<HTMLDivElement | null>(null);

  const { locale } = useLocale();

  const state = useTimeFieldState({
    ...props,
    locale,
  });

  const {
    fieldProps: { ...fieldProps },
  } = useTimeField(props, state, ref);

  return (
    <div
      {...fieldProps}
      ref={ref}
      data-test-id='time-field'
      className={cn(
        {
          [buttonVariants({ variant: 'outline' })]: withDefaultStyles,
        },
        'text-left flex items-start justify-start',
        className,
      )}
    >
      {state.segments.map((segment, i) => (
        <DateSegment
          className={classNames?.dateSegment}
          key={i}
          segment={segment}
          state={state}
        />
      ))}
    </div>
  );
}

export { TimeField };
