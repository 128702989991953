'use client ';
import React from 'react';
import { usePathname } from 'next/navigation';
import { cn } from 'ui';

export const AuthLayout = ({
  children,
  figures = true,
}: {
  children: React.ReactNode;
  figures?: boolean;
}) => {
  const pathname = usePathname();

  return (
    <div
      className={cn(
        'h-svh md:h-screen w-full flex flex-col md:overflow-y-hidden overflow-x-hidden bg-piramid-white relative',
        {
          'overflow-y-scroll': pathname === '/register',
          'overflow-y-hidden': pathname !== '/register',
        },
      )}
    >
      {figures && (
        <>
          <img
            src='/images/3d-shape.png'
            className='absolute md:-top-[5rem] md:-right-[7rem] -top-[10rem] rotate-[9deg] -right-16 z-0 w-[587px] h-[418px]'
          />
          <img
            src='/images/prisma-piramid.png'
            className='absolute hidden md:block md:-bottom-36 md:-left-20 z-0 w-[512px] h-[512px]'
          />
        </>
      )}
      <div className='w-full h-full p-6 flex items-center justify-center z-10'>
        <div className='max-w-4xl w-full h-full'>
          <div className='h-full flex items-center justify-center flex-col'>
            <div
              style={
                {
                  '--radius': `${0.5}rem`,
                } as React.CSSProperties
              }
              className='w-80 space-y-4 justify-items-center'
            >
              <div className='animate-fadein'>{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
