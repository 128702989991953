'use client';
import React from 'react';
import { cn } from 'ui';

export const Attribute = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn('!max-w-[15rem] !w-full', className)}>{children}</div>
  );
};

export const AttributeLabel = ({ children }: { children: React.ReactNode }) => {
  return <p className='text-stone-500 text-sm'>{children}</p>;
};

export const AttributeValue = ({ children }: { children: React.ReactNode }) => {
  return <p className='font-bold'>{children}</p>;
};

export const buildSinisterDetailQueryKey = (id: string) => `sinister-${id}`;
