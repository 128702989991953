'use client';

import { Dispatch, useState, useMemo, SetStateAction, useEffect } from 'react';
import union from 'lodash.union';
import { SEPARATORS } from '../constants/pagination';

type Arguments = {
  pagesCount?: number;
  innerLimit: number;
  outerLimit: number;
  currentPage: number;
};

export const getFirstItem = <T>(array: T[]): T => array[0];
export const getLastItem = <T>(array: T[]): T => array.slice(-1)[0];

export const isDecimalNumber = (number: number): boolean => {
  return number - Math.floor(number) !== 0;
};

export const generatePages = ({
  pagesCount,
  currentPage,
  innerLimit,
  outerLimit,
}: Arguments): number[] => {
  if (pagesCount == null) {
    return [];
  }

  const allPages = [...Array(pagesCount).keys()].map((page) => page + 1);

  if (innerLimit === 0 || outerLimit === 0) {
    return allPages;
  }

  const outerLeftPages = allPages.slice(0, outerLimit);
  const outerRightPages = allPages.slice(1).slice(-outerLimit);

  const lastPageOfOuterLeftPages = getLastItem(outerLeftPages);
  const firstPageOfOuterRightPages = getFirstItem(outerRightPages);

  const generateRightInnerPages = (): number[] => {
    const rightInnerLastIndex = currentPage + innerLimit;
    const isAfterFirstOuterRightPage =
      rightInnerLastIndex > firstPageOfOuterRightPages;

    return isAfterFirstOuterRightPage
      ? allPages.slice(currentPage + 1, firstPageOfOuterRightPages)
      : allPages.slice(currentPage, rightInnerLastIndex);
  };

  const generateLeftInnerPages = (): number[] => {
    const leftInnerFirstIndex = currentPage - innerLimit;
    const isBeforeLastOuterLeftPage =
      leftInnerFirstIndex < lastPageOfOuterLeftPages;

    return isBeforeLastOuterLeftPage
      ? allPages.slice(lastPageOfOuterLeftPages, currentPage - 1)
      : allPages.slice(leftInnerFirstIndex - 1, currentPage - 1);
  };

  const leftInnerPages = generateLeftInnerPages();
  const leftPages = union([...outerLeftPages], [...leftInnerPages]);
  const shouldHaveLeftSeparator =
    getFirstItem(leftInnerPages) > lastPageOfOuterLeftPages + 1;

  const rightInnerPages = generateRightInnerPages();
  const rightPages = union([...rightInnerPages], [...outerRightPages]);
  const shouldHaveRightSeparator =
    getLastItem(rightInnerPages) < firstPageOfOuterRightPages - 1;

  const unduplicatedPages = union(
    [...leftPages],
    [currentPage],
    [...rightPages],
  );

  const addSeparators = (pages: number[]): number[] =>
    pages.reduce<number[]>((acc: number[], page: number) => {
      const checkPageForSeparator = (): number[] => {
        if (page === lastPageOfOuterLeftPages && shouldHaveLeftSeparator) {
          return [lastPageOfOuterLeftPages, SEPARATORS.left];
        }

        if (page === firstPageOfOuterRightPages && shouldHaveRightSeparator) {
          return [SEPARATORS.right, firstPageOfOuterRightPages];
        }

        return [page];
      };

      return [...acc, ...checkPageForSeparator()];
    }, []);

  const pages = addSeparators(unduplicatedPages);

  return pages;
};

type InitialState = {
  currentPage: number;
  pageSize?: number;
  isDisabled?: boolean;
};

type Limits = {
  inner: number;
  outer: number;
};

type UsePagination = {
  initialState: InitialState;
  total?: number;
  pagesCount?: number;
  limits?: Limits;
};

export const usePaginationWindow = ({
  total,
  initialState,
  pagesCount: pagesCountProp,
  limits,
}: UsePagination): {
  offset: number;
  pages: number[];
  pagesCount: number;
  currentPage: number;
  pageSize: number;
  isDisabled: boolean;
  setPageSize: Dispatch<SetStateAction<number>>;
  setIsDisabled: Dispatch<SetStateAction<boolean>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
} => {
  // states
  const [pageSize, setPageSize] = useState<number>(initialState.pageSize ?? 0);
  const [currentPage, setCurrentPage] = useState<number>(
    initialState.currentPage,
  );
  const [isDisabled, setIsDisabled] = useState<boolean>(
    initialState.isDisabled ?? false,
  );

  // memos
  const innerLimit = useMemo(() => limits?.inner ?? 0, [limits]);
  const outerLimit = useMemo(() => limits?.outer ?? 0, [limits]);

  const offset = useMemo(() => {
    if (pageSize == null) {
      return 0;
    }

    return currentPage * pageSize - pageSize;
  }, [currentPage, pageSize]);

  const pagesCount = useMemo(() => {
    if (pagesCountProp != null) {
      return pagesCountProp;
    }

    if (total == null || pageSize == null) {
      return 0;
    }

    return Math.ceil(total / pageSize);
  }, [total, pageSize, pagesCountProp]);

  const pages = useMemo(
    () =>
      generatePages({
        currentPage,
        innerLimit,
        outerLimit,
        pagesCount,
      }),
    [currentPage, innerLimit, outerLimit, pagesCount],
  );

  // effects
  useEffect(() => {
    if (innerLimit != null && isDecimalNumber(innerLimit)) {
      console.error(
        'Ajna pagination -> passed down inner limit has to be a whole number',
      );
    }

    if (outerLimit != null && isDecimalNumber(outerLimit)) {
      console.error(
        'Ajna pagination -> passed down outerLimit limit has to be a whole number',
      );
    }
  }, [innerLimit, limits, outerLimit]);

  return {
    offset,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    isDisabled,
    setIsDisabled,
    pages,
    pagesCount,
  };
};
