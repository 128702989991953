export const INITIAL_VALUES = {
  currentPage: 1,
  isDisabled: false,
  jumpSize: 4,
};

export const SEPARATORS = {
  left: 0,
  right: -1,
};
