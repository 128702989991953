import { FileRequestPlaceholderWithRelations } from 'bff';
import { format } from 'date-fns';
import React from 'react';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Label,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  cn,
} from 'ui';
import { FileList, FileListItem, FileListLabel } from './File';

const Container = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <div className={cn('mt-4 space-y-6', className)}>{children}</div>;
};

export const FileListPlaceholder = ({
  placeholder,
}: {
  placeholder: FileRequestPlaceholderWithRelations;
}) => {
  return (
    <div>
      <Label>{placeholder.tag.name}</Label>
      <FileList>
        {placeholder.files.map(
          (
            { mimetype, original_name, url, uploaded_by, created_at },
            index,
          ) => {
            return (
              <FileListItem className='relative w-full' key={index}>
                <a className='hover:underline' target='_blank' href={url}>
                  <FileListLabel mimetype={mimetype}>
                    {original_name}
                  </FileListLabel>
                </a>
                {uploaded_by && (
                  <div className='absolute right-0 -bottom-2'>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <Avatar size='xs'>
                            <AvatarImage src={uploaded_by!.profile_photo!} />
                            <AvatarFallback className='text-xs'>
                              {`${uploaded_by!.first_name.charAt(
                                0,
                              )}${uploaded_by!.last_name?.charAt(0)}`}
                            </AvatarFallback>
                          </Avatar>
                        </TooltipTrigger>
                        <TooltipContent className='text-xs'>
                          subido el {format(new Date(created_at), 'Pp')}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                )}
              </FileListItem>
            );
          },
        )}
      </FileList>
    </div>
  );
};

FileListPlaceholder.Container = Container;
