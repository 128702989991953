'use client';

import React from 'react';
import {
  useEnablement,
  useSafeEnablement,
} from '../contexts/EnablementContext';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'ui';

export const withEnablement = <T extends object>(Component: React.FC<T>) => {
  return function EnablementElement(props: T) {
    const enablement = useSafeEnablement();

    if (!enablement) return <Component {...props} />;

    if (enablement.disabled)
      return (
        <TooltipProvider delayDuration={200}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className='cursor-not-allowed'>
                <div className='pointer-events-none opacity-50'>
                  <Component {...props} />
                </div>
              </div>
            </TooltipTrigger>
            <TooltipContent>
              {enablement.reason || 'No puedes realizar esta acción'}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );

    return <Component {...props} />;
  };
};
