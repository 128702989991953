import { useQueryClient } from '@tanstack/react-query';
import {
  FileRequestPlaceholderWithRelations,
  FileRequestWithRelations,
} from 'bff';
import { useCallback, useState } from 'react';
import {
  FileListPlaceholder,
  UploadFile,
  buildDocumentationQueryKey,
  useApiError,
} from 'shared-components';
import { Button, Label, useToast } from 'ui';
import wretch from 'wretch';
import FormDataAddon from 'wretch/addons/formData';
import { MESSAGE_QUERY_KEY } from './Chat';
import { FileTypes } from 'database';
import { useChat } from '../contexts/ChatContext';
import { useSharedClaim } from '../contexts/SharedClaimContext';
import { withEnablement } from '../hocs/withEnablement';

export const buildClaimDocumentationDataListQueryKey = (claimId: string) =>
  `claim-${claimId}-files`;

const UnsafeFileRequestPlaceholder = ({
  placeholder,
}: {
  placeholder: FileRequestPlaceholderWithRelations;
}) => {
  const [files, setFiles] = useState<
    {
      token: string;
      name: string;
      size: number;
    }[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const { confirmFileRequestPlaceholder, tokenizeFile } = useChat();

  const { claim } = useSharedClaim();

  const { handleError } = useApiError();

  const { toast } = useToast();

  const queryClient = useQueryClient();

  const handleConfirmPlaceholder = useCallback(() => {
    setIsLoading(true);

    confirmFileRequestPlaceholder({
      params: {
        relationTypeId: claim.id,
        fileRequestId: placeholder.file_request_id,
        placeholderId: placeholder.id,
      },
      data: {
        files,
      },
    })
      .then(() => {
        queryClient.invalidateQueries([MESSAGE_QUERY_KEY]);

        queryClient.invalidateQueries([
          buildClaimDocumentationDataListQueryKey(claim.id),
        ]);

        queryClient.invalidateQueries([buildDocumentationQueryKey()]);

        toast({
          title: 'Documentación enviada',
          description: 'La documentación ha sido enviada correctamente',
        });
      })
      .finally(() => setIsLoading(false))
      .catch(handleError);
  }, [files]);

  const { readOnly } = useChat();

  return (
    <div className='flex flex-col space-y-2 h-full' key={placeholder.id}>
      <Label>{placeholder.tag.name}</Label>
      <UploadFile
        disabled={readOnly}
        fileTypes={placeholder.types as FileTypes[]}
        onRemoveFile={(index) =>
          setFiles((files) => files.filter((_, i) => i !== index))
        }
        maxFiles={placeholder.max_files}
        onUploadFile={async (file) => {
          const { fields, presigned_url, token, fetch_url } =
            await tokenizeFile({
              data: {
                name: file.name,
                size: file.size,
              },
            });

          await wretch(presigned_url)
            .addon(FormDataAddon)
            .formData({
              ...fields,
              file,
            })
            .post();

          setFiles((files) =>
            files.concat([
              {
                name: file.name,
                size: file.size,
                token,
              },
            ]),
          );

          return {
            url: fetch_url,
            token,
          };
        }}
      >
        {placeholder.context && (
          <p className='text-sm text-muted-foreground'>{placeholder.context}</p>
        )}
      </UploadFile>

      {files.length > 0 && (
        <div className='flex flex-start items-start'>
          <Button
            disabled={isLoading}
            onClick={handleConfirmPlaceholder}
            loading={isLoading}
            type='button'
            className='text-xs'
            size='xs'
          >
            Confirmar
          </Button>
        </div>
      )}
    </div>
  );
};

const FileRequestPlaceholder = withEnablement(UnsafeFileRequestPlaceholder);

export const FileRequestMessage = ({
  fileRequest,
  className,
}: {
  fileRequest: FileRequestWithRelations;
  className?: string;
}) => {
  return (
    <FileListPlaceholder.Container className={className}>
      {fileRequest.placeholders.map((placeholder, index) => {
        if (placeholder.dirty)
          return (
            <FileListPlaceholder
              key={placeholder.id}
              placeholder={placeholder}
            />
          );

        return (
          <FileRequestPlaceholder
            key={placeholder.id}
            placeholder={placeholder}
          />
        );
      })}
    </FileListPlaceholder.Container>
  );
};
