import { zodResolver } from '@hookform/resolvers/zod';
import { IconTransfer } from '@tabler/icons-react';
import { transferClaimRequestBody } from 'bff';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  useToast,
} from 'ui';
import { z } from 'zod';
import { useApiError } from '../hooks/useApiError';
import { withEnablement } from '../hocs/withEnablement';

const schema = transferClaimRequestBody;

type FormData = z.infer<typeof schema>;

const TransferClaimForm = ({
  transferClaim,
  onClose,
}: {
  transferClaim: (data: FormData) => Promise<any>;
  onClose: () => void;
}) => {
  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);

  const { handleError } = useApiError();

  const { toast } = useToast();

  const handleSubmit = useCallback((data: FormData) => {
    setIsLoading(true);

    transferClaim(data)
      .then(() => {
        toast({
          title: 'Solicitud de transferencia enviada',
          description: `La solicitud de transferencia ha sido enviada correctamente al usuario ${data.destination_user_email}`,
        });

        onClose();
      })
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Form {...form}>
      <form className='space-y-4' onSubmit={form.handleSubmit(handleSubmit)}>
        <FormField
          control={form.control}
          name='destination_user_email'
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel>Correo</FormLabel>
                <FormControl>
                  <Input {...field} type='email' />
                </FormControl>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        <div className='w-full flex justify-end items-end'>
          <Button loading={isLoading} type='submit'>
            Enviar Solicitud
          </Button>
        </div>
      </form>
    </Form>
  );
};

const UnsafeTransferClaimButton = ({
  transferClaim,
}: {
  transferClaim: (data: FormData) => Promise<any>;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger>
        <Button size='sm'>
          <IconTransfer className='w-4 h-4 mr-2' />
          Transferir reclamo
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Transferir reclamo</DialogTitle>
          <DialogDescription>
            Ingresa el correo del usuario al que deseas transferir la propiedad
            el reclamo. Una vez la transferencia sea aceptada por el usuario,
            pasaras a tener acceso de lectura al reclamo actual
          </DialogDescription>
        </DialogHeader>
        <TransferClaimForm
          onClose={() => setIsOpen(false)}
          transferClaim={transferClaim}
        />
      </DialogContent>
    </Dialog>
  );
};

export const TransferClaimButton = withEnablement(UnsafeTransferClaimButton);
