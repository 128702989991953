import React, { FC, ReactElement, MouseEvent, useMemo } from 'react';

import { PaginationButton, PaginationItem, cn } from 'ui';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { usePaginationContext } from '../hooks/usePaginationContext';
import { PaginationWindowSeparator } from './PaginationWindowSeparator';
import { SEPARATORS } from '../constants/pagination';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {}

export type PageProps = {
  page: number;
  separator?: ReactElement<React.HTMLAttributes<HTMLButtonElement>>;
  _current?: ButtonProps;
};

// constants
const buttonStyles: ButtonProps = {
  minW: 'auto',
  px: 1,
  cursor: 'pointer',
};

export const PaginationWindowPage: FC<PageProps & ButtonProps> = ({
  page,
  isDisabled: isDisabledProp,
  separator,
  _current = {},
  ...buttonProps
}) => {
  // provider
  const { actions, state } = usePaginationContext();
  const { changePage } = actions;
  const { currentPage, isDisabled: isDisabledGlobal } = state;

  // memos
  const isCurrent = useMemo(() => currentPage === page, [currentPage, page]);
  const isDisabled = useMemo(
    () => isDisabledProp ?? isDisabledGlobal,
    [isDisabledGlobal, isDisabledProp],
  );
  const isSeparatorDisabled = useMemo(
    () => separator?.props?.isDisabled ?? isDisabledGlobal,
    [isDisabledGlobal, separator?.props?.isDisabled],
  );
  const currentStyles = useMemo(
    () => (isCurrent ? _current : {}),
    [isCurrent, _current],
  );
  const isLeftSeparator = useMemo(() => page === SEPARATORS.left, [page]);
  const isRightSeparator = useMemo(() => page === SEPARATORS.right, [page]);
  const pageLabel = useMemo(
    () => (isCurrent ? `Current page, page ${page}` : `Go to page ${page}`),
    [isCurrent, page],
  );
  const allProps = useMemo(
    () => ({
      ...buttonStyles,
      ...buttonProps,
      ...currentStyles,
      isDisabled,
    }),
    [buttonProps, currentStyles, isDisabled],
  );

  if (isRightSeparator) {
    return (
      <PaginationWindowSeparator
        isDisabled={isSeparatorDisabled}
        separatorPosition='right'
      />
    );
  }

  if (isLeftSeparator) {
    return (
      <PaginationWindowSeparator
        isDisabled={isSeparatorDisabled}
        separatorPosition='left'
      />
    );
  }

  return (
    <PaginationButton
      onClick={() => changePage(page)}
      className={cn(
        'w-[1.6875rem] h-[1.25rem] text-[0.625rem] rounded-[0.375rem] border-[0.0625rem]',
        {
          'border-primary': isCurrent,
          'text-zinc-200': !isCurrent,
        },
      )}
      href='#'
    >
      {page}
    </PaginationButton>
  );
};
