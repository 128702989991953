'use client';

import { IconHourglassEmpty } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import {
  ClaimOfferMinimalDetail,
  FetchClaimDetailResponseBody,
  ListClaimOffersResponseBody,
} from 'bff';

import { Button } from 'ui';
import { EmptyClaimTabContent } from './ClaimTabContent';
import { currencyFormat } from 'utils';
import {
  ClaimOfferList,
  OfferAgreementCard,
  OfferAgreementCardActionActor,
  OfferAgreementCardAttribute,
  OfferAgreementCardAttributeLabel,
  OfferAgreementCardAttributeValue,
  OfferAgreementCardContent,
  OfferAgreementCardFooter,
  OfferAgreementCardHeader,
  OfferAgreementCardStatus,
  OfferAgreementCardTitle,
  OfferAgreementDetail,
} from '..';
import { withEnablement } from '../hocs/withEnablement';

export const buildOffersQueryKey = (claimId: string) =>
  `claim-${claimId}-offers`;

const UnsafeAnswerOfferButton = ({
  offer,
}: {
  offer: ClaimOfferMinimalDetail;
}) => {
  return (
    <a href={`/claim/offer/${offer.id}?token=${offer.token}`} target='_blank'>
      <Button className='text-xs' size='sm'>
        Responder
      </Button>
    </a>
  );
};

const AnswerOfferButton = withEnablement(UnsafeAnswerOfferButton);

const OfferItem = ({
  offer,
  created_by,
  prefixPath,
}: {
  offer: ClaimOfferMinimalDetail;
  created_by: FetchClaimDetailResponseBody['created_by'];
  prefixPath: string;
}) => {
  console.log('offer', offer);

  return (
    <OfferAgreementCard>
      <OfferAgreementCardStatus status={offer.status} />
      <OfferAgreementCardHeader>
        <OfferAgreementCardTitle>Ofrecimiento</OfferAgreementCardTitle>
      </OfferAgreementCardHeader>
      <OfferAgreementCardContent>
        <OfferAgreementCardAttribute>
          <OfferAgreementCardAttributeLabel>
            Nombre
          </OfferAgreementCardAttributeLabel>
          <OfferAgreementCardAttributeValue>
            {offer.name}
          </OfferAgreementCardAttributeValue>
        </OfferAgreementCardAttribute>
        {offer.variable_values.map((variable) => {
          return (
            <OfferAgreementCardAttribute key={variable.id}>
              <OfferAgreementCardAttributeLabel>
                {variable.name}
              </OfferAgreementCardAttributeLabel>
              <OfferAgreementCardAttributeValue>
                {variable.value}
              </OfferAgreementCardAttributeValue>
            </OfferAgreementCardAttribute>
          );
        })}
      </OfferAgreementCardContent>
      <OfferAgreementCardFooter status={offer.status}>
        {!!offer.rejected_reason && (
          <div className='w-full space-y-2'>
            <p className='text-xs italic'>Motivo: "{offer.rejected_reason}"</p>
            {offer.third_party_user_requested_amount && (
              <p className='text-xs'>
                Pretensión:{' '}
                {currencyFormat(offer.third_party_user_requested_amount)}
              </p>
            )}
            <OfferAgreementCardActionActor
              date={offer.rejected_at!}
              first_name={created_by.first_name}
              last_name={created_by.last_name}
            />
          </div>
        )}

        {offer.status === 'pending' && <AnswerOfferButton offer={offer} />}
      </OfferAgreementCardFooter>
      {offer.agreement && (
        <OfferAgreementDetail
          agreement={offer.agreement}
        ></OfferAgreementDetail>
      )}
    </OfferAgreementCard>
  );
};

export const ClaimOwnerOffersDataTable = ({
  initialData,
  listOffers,
  queryKey,
  ...props
}: {
  initialData: ListClaimOffersResponseBody;
  listOffers: () => Promise<ListClaimOffersResponseBody>;
  queryKey: string;
  created_by: FetchClaimDetailResponseBody['created_by'];
  prefixPath: string;
}) => {
  const query = useQuery({
    queryKey: [buildOffersQueryKey(queryKey)],
    queryFn: () => listOffers(),
    initialData,
  });

  if (!query.data.offers.length)
    return <EmptyClaimTabContent label='Sin ofrecimiento recibido' />;

  return (
    <ClaimOfferList>
      {query.data.offers.map((offer, index) => (
        <OfferItem key={index} {...props} offer={offer} />
      ))}
    </ClaimOfferList>
  );
};
