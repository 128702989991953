'use client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AddMessageRequestBody, addMessageRequestBody } from 'bff';
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Message, useApiError } from 'shared-components';
import {
  Button,
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  ScrollArea,
  Separator,
  Skeleton,
} from 'ui';
import { FileRequestMessage } from './FileRequestMessage';
import { useChat } from '../contexts/ChatContext';
import { withEnablement } from '../hocs/withEnablement';

type FormData = AddMessageRequestBody;

export const MESSAGE_QUERY_KEY = 'messages';

const MessageSeparator = () => {
  return <Separator className='my-6' />;
};

const MessagesLoadingState = () => {
  const SAMPLE_MESSAGES_COUNT = 5;

  return (
    <div className='flex flex-col'>
      {new Array(SAMPLE_MESSAGES_COUNT).fill(null).map((_, index) => {
        return (
          <div key={index}>
            <div className='flex items-center space-x-4'>
              <Skeleton className='h-12 w-12 rounded-full' />
              <div className='space-y-2'>
                <Skeleton className='h-4 w-[250px]' />
                <Skeleton className='h-4 w-[200px]' />
              </div>
            </div>
            {index + 1 !== SAMPLE_MESSAGES_COUNT && (
              <Separator className='my-6' />
            )}
          </div>
        );
      })}
    </div>
  );
};

const MessagesContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <ScrollArea
      orientation='vertical'
      className='flex flex-col h-[600px] w-full'
    >
      {children}
    </ScrollArea>
  );
};

const Messages = () => {
  const { listMessages } = useChat();

  const { isLoading, data, error } = useQuery([MESSAGE_QUERY_KEY], () =>
    listMessages(),
  );

  if (isLoading || error || !data) {
    return (
      <MessagesContainer>
        <MessagesLoadingState />
      </MessagesContainer>
    );
  }

  return (
    <ScrollArea
      withContentPadding
      orientation='vertical'
      className='flex flex-col h-[600px] w-full'
    >
      {data.messages.map((m, index) => {
        return (
          <div key={m.id}>
            <Message>
              <Message.Avatar sender={m.sender as any} />
              <Message.Content>
                <Message.Title
                  sender={m.sender as any}
                  created_at={m.created_at}
                />
                <div>
                  <Message.Text>{m.message}</Message.Text>
                  {m.type === 'file_request' && m.file_request && (
                    <FileRequestMessage fileRequest={m.file_request!} />
                  )}
                </div>
              </Message.Content>
            </Message>
            {index + 1 !== data.messages.length && <MessageSeparator />}
          </div>
        );
      })}
    </ScrollArea>
  );
};

const UnsafeSendMessageButton = () => {
  return <Button>Enviar</Button>;
};

const SendMessageButton = withEnablement(UnsafeSendMessageButton);

export const Chat = () => {
  const form = useForm<FormData>({
    resolver: zodResolver(addMessageRequestBody),
  });

  const [isLoading, setIsLoading] = useState(false);

  const { addMessage, readOnly } = useChat();

  const queryClient = useQueryClient();

  const { handleError } = useApiError();

  const handleSubmit = useCallback((data: FormData) => {
    setIsLoading(true);

    addMessage(data.message)
      .then(() => {
        queryClient.invalidateQueries([MESSAGE_QUERY_KEY]);
        form.reset();
      })
      .catch(handleError);
  }, []);

  return (
    <Card className='w-full'>
      <CardHeader>
        <CardTitle>Chat</CardTitle>
        <CardDescription>
          Envía un mensaje al tramitador de tu reclamo
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Messages />
      </CardContent>
      {!readOnly && (
        <CardFooter>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className='flex flex-row space-x-2 w-full'
            >
              <FormField
                defaultValue=''
                control={form.control}
                name='message'
                render={({ field }) => {
                  return (
                    <FormItem className='w-full'>
                      <FormControl>
                        <Input className='w-full' {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <SendMessageButton />
            </form>
          </Form>
        </CardFooter>
      )}
    </Card>
  );
};
