'use client';

import React, { useContext } from 'react';

export interface EnablementContextProps {
  disabled?: boolean;
  reason?: string;
}

const EnablementContext = React.createContext<
  EnablementContextProps | undefined
>(undefined);

export const useEnablement = () => useContext(EnablementContext)!;

export const useSafeEnablement = () => useContext(EnablementContext);

export const EnablementProvider = ({
  children,
  ...props
}: EnablementContextProps & {
  children: React.ReactNode;
}) => {
  return (
    <EnablementContext.Provider value={props}>
      {children}
    </EnablementContext.Provider>
  );
};
