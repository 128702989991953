import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  Collapsible,
  cn,
} from 'ui';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarProvider,
  SidebarTrigger,
} from '../../../packages/ui/components/SideBar';
import { Calendar, Home, Inbox, Search, Settings } from 'lucide-react';
import {
  PathSlugs,
  PiramidLogo,
  useApiError,
  useEvaluatePathSlug,
  useSharedAuth,
} from 'shared-components';
import {
  IconCaretDown,
  IconChevronDown,
  IconFocusCentered,
  IconHourglassEmpty,
  IconRadar2,
  IconSquare,
  IconTimeline,
  IconUserSquare,
} from '@tabler/icons-react';
// import { useAuth } from '@/contexts/AuthContext';
import { buildUserFullName } from 'utils';
import Link from 'next/link';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useMemo, useState } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { IconUrgent } from '@tabler/icons-react';
import { CollapsibleContent, CollapsibleTrigger } from 'ui';
import { IconCaretDownFilled } from '@tabler/icons-react';
import { Icon } from '@tabler/icons-react';

const LogoutButton = ({ logoutUser }: { logoutUser: () => Promise<any> }) => {
  const router = useRouter();

  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);

  const { handleError } = useApiError();

  const onLogout = useCallback(() => {
    setIsLoading(true);
    logoutUser()
      .then(() => {
        queryClient.clear();

        window.location.href = '/login';
      })
      .catch(handleError);
  }, [logoutUser]);

  return (
    <DropdownMenuItem onClick={onLogout} className='px-3'>
      <span className='text-base_brand text-[#44403C]'>Cerrar sesión</span>
    </DropdownMenuItem>
  );
};

const useGetAllSearchParams = () => {
  const searchParams = useSearchParams();
  const params: { [anyProp: string]: string } = {};

  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  return params;
};

export const buildQueryString = (obj: any) => {
  const queryString = Object.keys(obj)
    .filter(
      (key) => obj[key] !== '' && obj[key] !== undefined && obj[key] !== null,
    )
    .map(
      (key: string) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`,
    )
    .join('&');

  return queryString;
};

const iconStrokeWidth = 1.7;

interface MenuItemBase {
  title: string;
  icon: Icon;
}

interface MenuItemLink extends MenuItemBase {
  href: string;
  collapsible?: false | null;
}

interface MenuItemCollapsible extends MenuItemBase {
  collapsible: true;
  items: MenuItemLink[];
  separated?: boolean;
  groupIndex?: number;
}

export type MenuItem = MenuItemLink | MenuItemCollapsible;

interface SidebarComponents {
  headerCorner?: React.FC;
  logo?: React.FC;
  headerTitle?: React.ReactNode;
}

const AppSidebar = ({
  logoutUser,
  items,
  components,
}: {
  logoutUser: () => Promise<any>;
  items: MenuItem[];
  components?: SidebarComponents;
}) => {
  const auth = useSharedAuth();

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const router = useRouter();

  const allSearchParams = useGetAllSearchParams();

  const queryString = buildQueryString(allSearchParams);

  const fullPath = `${pathname}${queryString ? `?${queryString}` : ''}`;

  return (
    <Sidebar>
      <div className='px-[0.875rem] py-[1.125rem] space-y-[1.875rem]'>
        <SidebarHeader className='p-0'>
          <SidebarMenu>
            <SidebarMenuItem className='flex flex-row items-center justify-between'>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <SidebarMenuButton className='w-fit'>
                    {components?.logo ? <components.logo /> : <PiramidLogo />}

                    <span className='text-base_brand font-semibold text-[#44403C] max-w-32 truncate'>
                      {components?.headerTitle || 'Piramid'}
                    </span>
                    <IconChevronDown className='!size-[0.625rem] text-base_brand flex-shrink-0' />
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent side='bottom' align='start'>
                  <div className='text-base_brand pt-3 px-3'>
                    <p className='leading-[0.813rem] truncate text-[#44403C]'>
                      {buildUserFullName(auth.user)} <br />
                      <span className='text-[0.688rem] text-[#A8A29E]'>
                        {auth.user.email}
                      </span>
                    </p>
                  </div>
                  <DropdownMenuSeparator />

                  <DropdownMenuItem asChild className='px-3'>
                    <Link href='/settings'>
                      <span className='text-base_brand text-[#44403C]'>
                        Configuración
                      </span>
                    </Link>
                  </DropdownMenuItem>
                  <LogoutButton logoutUser={logoutUser} />
                </DropdownMenuContent>
              </DropdownMenu>
              {components?.headerCorner && <components.headerCorner />}
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarHeader>
        <SidebarContent>
          <SidebarMenu>
            {items.map((item, index) => {
              if (item.collapsible)
                return (
                  <Collapsible
                    key={item.title}
                    defaultOpen
                    className='group/collapsible'
                  >
                    <SidebarMenuItem
                      className={cn({
                        'mt-[1.875rem]':
                          item.separated && item.groupIndex === 0,
                      })}
                    >
                      <CollapsibleTrigger
                        className='[&[data-state=open]>svg]:rotate-180'
                        asChild
                      >
                        <SidebarMenuButton className='hover:!bg-stone-200'>
                          <item.icon
                            strokeWidth={iconStrokeWidth}
                            className='!rotate-0'
                          />
                          <span className='font-medium text-stone-700 text-base_brand'>
                            {item.title}
                          </span>
                          <IconCaretDownFilled
                            style={{
                              width: '0.75rem',
                              height: '0.75rem',
                            }}
                            className='!size-3 text-stone-700'
                          />
                        </SidebarMenuButton>
                      </CollapsibleTrigger>
                      <CollapsibleContent className='overflow-x-hidden'>
                        <SidebarMenuSub className='border-0 px-0 mx-0 overflox-w-hidden w-full'>
                          {item.items.map((item) => {
                            return (
                              <SidebarMenuSubItem className='w-full'>
                                <SidebarMenuSubButton
                                  className={cn('hover:bg-stone-200', {
                                    'bg-stone-300': searchParams.get('team_id')
                                      ? fullPath === item.href
                                      : fullPath.startsWith(item.href),
                                  })}
                                  asChild
                                >
                                  <Link
                                    className='w-full px-8'
                                    href={item.href}
                                  >
                                    <item.icon
                                      strokeWidth={iconStrokeWidth}
                                      className='text-stone-700 !size-[1.125rem]'
                                    />
                                    <span className='font-medium text-stone-700 text-base_brand'>
                                      {item.title}
                                    </span>
                                  </Link>
                                </SidebarMenuSubButton>
                              </SidebarMenuSubItem>
                            );
                          })}
                        </SidebarMenuSub>
                      </CollapsibleContent>
                    </SidebarMenuItem>
                  </Collapsible>
                );
              return (
                <SidebarMenuItem key={item.title}>
                  <SidebarMenuButton
                    className={cn('hover:bg-stone-200', {
                      'bg-stone-300': searchParams.get('team_id')
                        ? fullPath === item.href
                        : fullPath.startsWith(item.href),
                    })}
                    asChild
                  >
                    <Link href={item.href}>
                      <item.icon
                        strokeWidth={iconStrokeWidth}
                        className='text-stone-700 !size-[1.125rem]'
                      />
                      <span className='font-medium text-stone-700 text-base_brand'>
                        {item.title}
                      </span>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              );
            })}
          </SidebarMenu>
        </SidebarContent>
      </div>
    </Sidebar>
  );
};

export const SidebarDashboardLayout = ({
  children,
  logoutUser,
  items,
  nextSlugs = [],
  components,
}: {
  children: React.ReactNode;
  logoutUser: () => Promise<any>;
  items: MenuItem[];
  nextSlugs?: PathSlugs;
  components?: SidebarComponents;
}) => {
  const isNextSlug = useEvaluatePathSlug(nextSlugs);

  return (
    <SidebarProvider>
      {!isNextSlug && (
        <AppSidebar
          components={components}
          items={items}
          logoutUser={logoutUser}
        />
      )}

      <div
        className={cn('w-full bg-stone-50 overflow-auto', {
          'p-14': !isNextSlug,
          'flex justify-center': !isNextSlug,
        })}
      >
        <div
          className={cn('w-full', {
            'max-w-full': isNextSlug,
            'max-w-7xl ': !isNextSlug,
          })}
        >
          {children}
        </div>
      </div>
    </SidebarProvider>
  );
};
