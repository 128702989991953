'use client';
import { IconMoneybag } from '@tabler/icons-react';
import { OfferStatus } from 'database';
import { format } from 'date-fns';
import { useTranslations } from 'i11n';
import React from 'react';
import {
  Badge,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Separator,
  cn,
} from 'ui';
import { FileList, FileListItem, FileListLabel } from './File';
import { ClaimOfferMinimalDetail } from 'bff';
import { NonUndefined } from 'react-hook-form';

export const OfferAgreementDetail = ({
  agreement,
  children,
}: {
  agreement: NonNullable<NonUndefined<ClaimOfferMinimalDetail['agreement']>>;
  children?: React.ReactNode;
}) => {
  const t = useTranslations();

  return (
    <div>
      <Separator className='mb-6' />
      <div className='flex flex-col space-y-6'>
        <div className='px-6'>
          <OfferAgreementCardTitle>Conformidad</OfferAgreementCardTitle>
        </div>

        <OfferAgreementCardContent>
          <OfferAgreementCardAttribute>
            <OfferAgreementCardAttributeLabel>
              Nombre
            </OfferAgreementCardAttributeLabel>
            <OfferAgreementCardAttributeValue>
              {agreement.name}
            </OfferAgreementCardAttributeValue>
          </OfferAgreementCardAttribute>

          <OfferAgreementCardAttribute>
            <OfferAgreementCardAttributeLabel>
              Proveedor
            </OfferAgreementCardAttributeLabel>
            <OfferAgreementCardAttributeValue>
              {agreement.provider_type}
            </OfferAgreementCardAttributeValue>
          </OfferAgreementCardAttribute>
          <OfferAgreementCardAttribute>
            <OfferAgreementCardAttributeLabel>
              Transacción
            </OfferAgreementCardAttributeLabel>
            <OfferAgreementCardAttributeValue>
              {agreement.provider_id}
            </OfferAgreementCardAttributeValue>
          </OfferAgreementCardAttribute>
          <OfferAgreementCardAttribute>
            <OfferAgreementCardAttributeLabel>
              Estado
            </OfferAgreementCardAttributeLabel>
            <OfferAgreementCardAttributeValue>
              {t(`offer.agreement.status.${agreement.status}`)}
            </OfferAgreementCardAttributeValue>
          </OfferAgreementCardAttribute>
          {agreement.canceled_at && (
            <OfferAgreementCardAttribute>
              <OfferAgreementCardAttributeLabel>
                Cancelado el
              </OfferAgreementCardAttributeLabel>
              <OfferAgreementCardAttributeValue>
                {format(new Date(agreement.canceled_at), 'Pp')}
              </OfferAgreementCardAttributeValue>
            </OfferAgreementCardAttribute>
          )}

          {agreement.expired_at && (
            <OfferAgreementCardAttribute>
              <OfferAgreementCardAttributeLabel>
                Expirado el
              </OfferAgreementCardAttributeLabel>
              <OfferAgreementCardAttributeValue>
                {format(new Date(agreement.expired_at), 'Pp')}
              </OfferAgreementCardAttributeValue>
            </OfferAgreementCardAttribute>
          )}

          {agreement.cancelled_reason && (
            <OfferAgreementCardAttribute>
              <OfferAgreementCardAttributeLabel>
                Motivo cancelación
              </OfferAgreementCardAttributeLabel>
              <OfferAgreementCardAttributeValue>
                {agreement.cancelled_reason}
              </OfferAgreementCardAttributeValue>
            </OfferAgreementCardAttribute>
          )}

          {agreement.rejected_at && (
            <OfferAgreementCardAttribute>
              <OfferAgreementCardAttributeLabel>
                Rechazado el
              </OfferAgreementCardAttributeLabel>
              <OfferAgreementCardAttributeValue>
                {format(new Date(agreement.rejected_at), 'Pp')}
              </OfferAgreementCardAttributeValue>
            </OfferAgreementCardAttribute>
          )}

          {agreement.rejected_reason && (
            <OfferAgreementCardAttribute>
              <OfferAgreementCardAttributeLabel>
                Motivo de rechazo
              </OfferAgreementCardAttributeLabel>
              <OfferAgreementCardAttributeValue>
                {agreement.rejected_reason}
              </OfferAgreementCardAttributeValue>
            </OfferAgreementCardAttribute>
          )}

          {agreement.completed_at && (
            <OfferAgreementCardAttribute>
              <OfferAgreementCardAttributeLabel>
                Completado el
              </OfferAgreementCardAttributeLabel>
              <OfferAgreementCardAttributeValue>
                {format(new Date(agreement.completed_at), 'Pp')}
              </OfferAgreementCardAttributeValue>
            </OfferAgreementCardAttribute>
          )}

          {agreement.signed_documents &&
            agreement.signed_documents.length > 0 && (
              <OfferAgreementCardAttribute>
                <OfferAgreementCardAttributeLabel>
                  Documentos firmados
                </OfferAgreementCardAttributeLabel>

                <FileList>
                  {(agreement.signed_documents || []).map((document, index) => {
                    return (
                      <a
                        className='group'
                        target='_blank'
                        key={document.id}
                        href={document.url}
                      >
                        <FileListItem
                          className='group-hover:underline'
                          key={index}
                        >
                          <FileListLabel mimetype={document.mimetype!}>
                            {document.original_name}
                          </FileListLabel>
                        </FileListItem>
                      </a>
                    );
                  })}
                </FileList>
              </OfferAgreementCardAttribute>
            )}

          {children}
        </OfferAgreementCardContent>
      </div>
    </div>
  );
};

export const OfferAgreementCardFooter = ({
  children,
  status,
  className,
}: {
  children: React.ReactNode;
  status: OfferStatus;
  className?: string;
}) => {
  const t = useTranslations();

  return (
    <CardFooter
      className={cn(
        'w-full justify-end items-end space-x-2',
        {
          'max-w-2xl items-start': status !== 'pending',
        },
        className,
      )}
    >
      {children}
    </CardFooter>
  );
};

export const OfferAgreementCardStatus = ({
  status,
}: {
  status: OfferStatus;
}) => {
  const t = useTranslations();

  return (
    <div className='absolute -top-3 right-0'>
      <Badge>{t(`claims.offer.status.${status}`)}</Badge>
    </div>
  );
};

export const OfferAgreementCardActionActor = ({
  date,
  first_name,
  last_name,
}: {
  first_name: string;
  last_name: string;
  date: Date | string;
}) => {
  return (
    <p className='text-muted-foreground text-xs text-left'>
      {first_name} {last_name} - {format(new Date(date!), 'dd/MM/yyyy HH:mm')}
    </p>
  );
};

export const OfferAgreementCard = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <Card className='relative overflow-none'>{children}</Card>;
};

export const OfferAgreementCardContent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <CardContent className='grid grid-cols-3 gap-6'>{children}</CardContent>
  );
};

export const OfferAgreementCardIcon = () => {
  return (
    <span className='w-6 h-6'>
      <IconMoneybag className='w-6 h-6' />
    </span>
  );
};

export const OfferAgreementCardTitle = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <div className='flex flex-row items-center space-x-2'>
      <CardTitle className='uppercase'>{children}</CardTitle>
    </div>
  );
};

export const OfferAgreementCardHeader = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <CardHeader className='flex flex-row items-center justify-between'>
      {children}
    </CardHeader>
  );
};

export const OfferAgreementCardAttribute = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className='flex flex-col space-y-1'>{children}</div>;
};

export const OfferAgreementCardAttributeLabel = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <p>{children}</p>;
};

export const OfferAgreementCardDocument = ({
  url,
  name,
  created_by_first_name,
  created_by_last_name,
  accepted_at,
  mimetype,
}: {
  url: string;
  name: string;
  created_by_first_name: string;
  created_by_last_name: string;
  accepted_at?: Date;
  mimetype?: string;
}) => {
  return (
    <div className='w-full space-y-2'>
      <a className='hover:underline !mt-0 italic' target='_blank' href={url}>
        <FileListItem className='border-0 w-full h-6 mr-2 !mt-0'>
          <FileListLabel mimetype={mimetype} className='w-full'>
            {name}
          </FileListLabel>
        </FileListItem>
      </a>
      <p className='text-muted-foreground text-xs text-left'>
        {created_by_first_name} {created_by_last_name} -{' '}
        {format(new Date(accepted_at!), 'dd/MM/yyyy HH:mm')}
      </p>
    </div>
  );
};

export const OfferAgreementCardRejectedReason = ({
  rejected_reason,
  created_by_first_name,
  created_by_last_name,
  rejected_at,
}: {
  rejected_reason: string;
  created_by_first_name: string;
  created_by_last_name: string;
  rejected_at: Date;
}) => {
  return (
    <div className='w-full space-y-2'>
      <p className='text-xs italic'>"{rejected_reason}"</p>
      <p className='text-muted-foreground text-xs text-left'>
        {created_by_first_name} {created_by_last_name} -{' '}
        {format(new Date(rejected_at!), 'dd/MM/yyyy HH:mm')}
      </p>
    </div>
  );
};

export const OfferAgreementCardAttributeValue = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <span className='font-bold'>{children}</span>;
};
