import { zodResolver } from '@hookform/resolvers/zod';
import {
  FetchInsurerClaimDetailResponseBody,
  ListVehicleMakeRequestQuery,
  ListVehicleMakeResponseBody,
  ListVehicleModelsRequestQuery,
  ListVehicleModelsResponseBody,
  ListVehicleVersionsRequestQuery,
  ListVehicleVersionsResponseBody,
  UpdateClaimRequestBody,
  updateClaimRequestBody,
} from 'bff';
import React, { useState } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { Form } from 'ui';
import { z } from 'zod';

export type ClaimUpdateSegment = keyof UpdateClaimRequestBody;

export interface ClaimUpdateContext {
  enabled?: boolean;
  editingSegment?: ClaimUpdateSegment;
  setEditingSegment: (segment?: ClaimUpdateSegment) => void;
  onSave: (data: UpdateClaimRequestBody) => Promise<void>;
  onInvalidate: () => void;
  form: UseFormReturn<UpdateClaimRequestBody>;
  data: FetchInsurerClaimDetailResponseBody;
  fetchers?: {
    listVehicleMake: (
      query: ListVehicleMakeRequestQuery,
    ) => Promise<ListVehicleMakeResponseBody>;
    listVehicleModel: (
      query: ListVehicleModelsRequestQuery,
    ) => Promise<ListVehicleModelsResponseBody>;
    listVehicleVersion: (
      query: ListVehicleVersionsRequestQuery,
    ) => Promise<ListVehicleVersionsResponseBody>;
  };
}

const ClaimUpdateContext = React.createContext<ClaimUpdateContext | undefined>(
  undefined,
);

export const useSafeClaimUpdate = (): Partial<ClaimUpdateContext> => {
  const context = React.useContext(ClaimUpdateContext);

  if (!context)
    return {
      enabled: false,
    };

  return context!;
};

const schema = updateClaimRequestBody;

type FormData = z.infer<typeof schema>;

export const ClaimUpdateProvider = ({
  onSave,
  children,
  data,
  onInvalidate,
  enabled,
  fetchers,
}: {
  onSave: (data: UpdateClaimRequestBody) => void;
  children: React.ReactNode;
  data: FetchInsurerClaimDetailResponseBody;
  onInvalidate: () => void;
  enabled?: boolean;
  fetchers?: ClaimUpdateContext['fetchers'];
}) => {
  const [editingSegment, setEditingSegment] = useState<ClaimUpdateSegment>();

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues: data,
  });

  console.log(
    'ClaimUpdateContext form fields',
    form.watch(),
    'editingSegment',
    editingSegment,
    'shape form',
    schema.shape.occurrence,
    'schema',
  );

  return (
    <Form {...form}>
      <ClaimUpdateContext.Provider
        value={{
          fetchers,
          enabled,
          editingSegment,
          setEditingSegment,
          onSave,
          form,
          onInvalidate,
          data,
        }}
      >
        {children}
      </ClaimUpdateContext.Provider>
    </Form>
  );
};
